import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo, createElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactMarkdown, { uriTransformer } from 'react-markdown';
import { visit } from 'unist-util-visit';
import remarkDirective from 'remark-directive';
import { Link } from 'react-router-dom';
import { Facebook, Instagram } from '@mui/icons-material';
import {
    Accordion, Card, CardContent, CardHeader,
    ClassicBanners, classicBannersClasses,
    ContactBlock, Reviews,
    TextImage,
    textImageClasses,
    Title as AthenaTitle, VideoWrapper, videoWrapperClasses,
} from '@athenagroup/components';
import YouTube from 'react-youtube';
import remarkGfm from 'remark-gfm';
import { getPagePath, getRouterPath } from '../../helpers/path';
import staticPages from '../../pages/static/staticPages';
import MarkdownButton from './MarkdownButton';
import MarkdownCard from './MarkdownCard';
import MarkdownIframe from './MarkdownIframe';
import MarkdownListTable from './MarkdownListTable';
import MarkdownQuote from './MarkdownQuote';
import TikTokIcon from '../general/TikTokIcon';
import { ContactFormComponent } from '../home/widgets/HomePageWidgetContactForm';
import useTransformedUri from '../../hoc/hooks/useTransformedUri';
import { LinkComponent } from '../general/LinkComponent';
import Thumbnails from '../home/widgets/Thumbnails';
import RoleCards from '../home/widgets/RoleCards';
import TextImageTabs from '../home/widgets/TextImageTabs';
import WidgetCarousel from '../home/widgets/WidgetCarousel';

const ITEM_TAG_NAME = 'item';
const STYLE_TAG_NAME = 'sx';
const socialMediaSX = { color: 'common.black', transform: 'translateY(4px)', mr: 1 };
const socialMediaIcons = {
    instagram: <Instagram fontSize="small" sx={socialMediaSX} />,
    tiktok: <TikTokIcon fontSize="small" sx={socialMediaSX} />,
    facebook: <Facebook fontSize="small" sx={{ ...socialMediaSX, color: '#3b5998' }} />,
};

const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
function convertNumericStrings(obj) {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
            key,
            typeof value === 'string' && isNumeric(value) ? Number(value) : value,
        ]),
    );
}

const mapChildrenProperties = (children) => children ? (children.filter(({ props: childProp }) => {
    const { node: { tagName } } = childProp;
    return tagName === ITEM_TAG_NAME;
}).map(({ props: { node, ...props } }) => ({
    ...convertNumericStrings(props),
    description: props.children,
}))
) : [];

const customUriTransformer = (uri) => {
    // Internal key and route linking, will be picked up by custom renderer
    if (uri.indexOf('key:') === 0 || uri.indexOf('route:') === 0) {
        return uri;
    }

    return uriTransformer(uri);
};

function customPlugin() {
    return (tree) => {
        visit(
            tree,
            ['textDirective', 'leafDirective', 'containerDirective'],
            (node) => {
                // eslint-disable-next-line no-param-reassign
                node.data = {
                    hName: node.name,
                    hProperties: node.attributes,
                    ...node.data,
                };
            },
        );
    };
}

function numericStringPlugin() {
    return (tree) => {
        visit(tree, 'element', (node) => {
            if (node.properties) {
                // eslint-disable-next-line no-param-reassign
                node.properties = convertNumericStrings(node.properties);
            }
        });
    };
}

function styleToPropsPlugin() {
    return (tree) => {
        visit(tree, 'element', (node) => {
            if (!node.children) return;
            const styleChildren = node.children.find(({ tagName }) => tagName === STYLE_TAG_NAME);
            if (!styleChildren) return;
            if (!styleChildren.properties) return;
            // eslint-disable-next-line no-param-reassign
            node.properties = {
                ...(node.properties),
                sx: styleChildren.properties,
            };
        });
    };
}

const customComponents = (i18n, pages) => ({
    quote: ({ node, children, ...props }) => (
        <MarkdownQuote {...props}>
            {children}
        </MarkdownQuote>
    ),
    socialMediaIcon: ({ node, children, ...props }) => (
        <Typography paragraph sx={{ mb: 1 }}>
            <a href={props.link} target="_blank" rel="noreferrer">
                {socialMediaIcons[props.type]}
                <span>
                    @
                    {props.user}
                </span>
            </a>
        </Typography>
    ),
    container: ({ node, children, ...props }) => {
        console.log('Node props', props);
        return (
            <Grid container columnGap={10} {...props}>
                {children}
            </Grid>
        );
    },
    grid: ({ node, children, ...props }) => (
        <Grid
            {...props}
            item
        >
            {children}
        </Grid>
    ),
    iframe: ({ node, children, ...props }) => (
        <MarkdownIframe src={children[0]} {...props} />
    ),
    listTable: ({ node, children, ...props }) => (
        <MarkdownListTable items={children[0]} {...props} />
    ),
    button: ({ node, children, ...props }) => (
        <MarkdownButton to={children[0]} {...props} />
    ),
    listIcon: ({ children, ...props }) => (
        <div className={`listIcon-${children[0]} ${props.color ? `listIconColor-${props.color}` : ''}`} />
    ),
    markdownCard: ({ node, children, ...props }) => (
        <MarkdownCard items={children[0]} {...props} />
    ),
    cardWrapper: ({ children }) => (
        <Grid container spacing={2} sx={{ my: 2 }}>
            {React.Children.toArray(children).map(children, (child, i) => (
                <Grid key={i} item md={6} xs={12}>
                    {child}
                </Grid>
            ))}
        </Grid>
    ),
    spacer: ({ spacing }) => (
        <Box my={parseInt(spacing, 10) || 2} />
    ),
    anchor: ({ children }) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
        children[0] ? <a id={children[0]} /> : null
    ),
    a: ({ node, ...props }) => {
        if (props.href.indexOf('key:') === 0) return (
            <Link to={getPagePath(pages, props.href.substring(4))}>{props.children}</Link>
        );

        if (props.href.indexOf('route:') === 0) return (
            <Link to={getRouterPath(i18n, props.href.substring(6), i18n.language, true)}>{props.children}</Link>
        );

        return createElement('a', props);
    },
    contactForm: ({ node, children, ...props }) => (
        <ContactFormComponent
            {...props}
        />
    ),
    // textImageTabs, banners, title, roleCards, reviews
    video: ({ node, children, ...props }) => (
        <VideoWrapper
            renderVideo={(params => (<YouTube {...params} videoId={props.videoId} />))}
            sx={{
                div: { height: '100%' },
                [`.${videoWrapperClasses.playerWrapper}`]: {
                    aspectRatio: '16/9.005',
                },
            }}
        />
    ),
    textImage: ({ node, children, ...props }) => {
        const { label, buttonTo } = props;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const to = useTransformedUri(buttonTo);

        return (
            <TextImage
                {...props}
                description={children}
                imgAlt=""
                variant="backdrop"
                alignImage="center"
                justifyContent="center"
                buttonLink={!to ? { href: buttonTo } : { to }}
                buttonLabel={label}
                ButtonLinkComponent={LinkComponent}
                sx={{ [`& .${textImageClasses.titleDescriptionButton} a`]: { borderRadius: '2px' } }}
                applyBorderRadius
            />
        );
    },
    thumbnail: ({ children, ...props }) => {
        const items = mapChildrenProperties(children);

        return (
            <Thumbnails
                items={items}
                {...props}
            />
        );
    },
    contactBlock: ({ node, children, ...props }) => (
        <ContactBlock {...props} />
    ),
    accordion: ({ children, ...props }) => {
        const items = mapChildrenProperties(children);

        return (
            <Accordion items={items} {...props} />
        );
    },
    title: ({ node, children, ...props }) => (
        <AthenaTitle {...props} description={children || props.description} />
    ),
    banners: ({ children, ...props }) => {
        const items = mapChildrenProperties(children);

        return (
            <ClassicBanners
                items={items.map(item => ({
                    ...item,
                    LinkComponent: item.href ? 'a' : Link,
                    linkProps: item.href ? { href: item.href } : { to: item.to && useTransformedUri(item.to) },
                }))}
                sx={{
                    [`.${classicBannersClasses.bannerWrapper}`]: items.length === 1 ? {
                        width: 'fit-content',
                        margin: '0 auto',
                    } : {},
                }}
                {...props}
            />
        );
    },
    reviews: ({ children, ...props }) => {
        const items = mapChildrenProperties(children);

        return (
            <Reviews
                items={items}
                {...props}
            />
        );
    },
    roleCards: ({ children, ...props }) => {
        const items = mapChildrenProperties(children);

        return (
            <RoleCards
                items={items}
                {...props}
            />
        );
    },
    carousel: ({ children, ...props }) => {
        const items = mapChildrenProperties(children);

        return (
            <WidgetCarousel items={items} {...props} />
        );
    },
    textImageTabs: ({ children, ...props }) => {
        const items = mapChildrenProperties(children);

        return (
            <TextImageTabs items={items} {...props} />
        );
    },
    card: ({ children, ...props }) => (
        <Card {...props}>
            {children}
        </Card>
    ),
    cardHeader: ({ children, ...props }) => (
        <CardHeader {...props}>
            {children}
        </CardHeader>
    ),
    cardContent: ({ children, ...props }) => (
        <CardContent {...props}>
            {children}
        </CardContent>
    ),
    typography: ({ children, ...props }) => (
        <Typography {...props}>
            {children}
        </Typography>
    ),
    item: () => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
    ),
    sx: () => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
    ),
});

function Markdown({ children }) {
    const { i18n } = useTranslation();
    const pages = useMemo(() => staticPages(i18n), [i18n]);

    const components = useMemo(() => ({
        ...customComponents(i18n, pages),
    }), [i18n, pages]);

    const remarkPlugins = useMemo(() => [
        remarkDirective,
        customPlugin,
        remarkGfm,
    ], []);

    const rehypePlugins = useMemo(() => [
        numericStringPlugin,
        styleToPropsPlugin,
    ], []);

    return (
        <ReactMarkdown
            className="markdown markdown-new"
            components={components}
            transformLinkUri={customUriTransformer}
            remarkPlugins={remarkPlugins}
            rehypePlugins={rehypePlugins}
        >
            {children}
        </ReactMarkdown>
    );
}

Markdown.propTypes = {
    children: PropTypes.node,
};

export default Markdown;
