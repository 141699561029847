import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Kpi } from '@athenagroup/components';
import { useTranslation } from 'react-i18next';
import HomePageWidgetContactBlock from './HomePageWidgetContactBlock';
import HomePageWidgetContactForm from './HomePageWidgetContactForm';
import HomePageWidgetReviews from './HomePageWidgetReviews';
import Thumbnails from './Thumbnails';
import HomePageWidgetVideo from './HomePageWidgetVideo';
import WidgetTitle from './WidgetTitle';
import { flattenLanguageProps } from '../../../helpers/locale';
import HomePageWidgetTextImage from './HomePageWidgetTextImage';
import TextImageTabs from './TextImageTabs';
import HomePageWidgetBanners from './HomePageWidgetBanners';
import HomePageWidgetTitle from './HomePageWidgetTitle';
import HomePageWidgetAccordion from './HomePageWidgetAccordion';
import WidgetCarousel from './WidgetCarousel';
import RoleCards from './RoleCards';
import CircleBackgroundBannersWrapper from './CircleBackgroundBannersWrapper';

const PREFIX = 'HomePageWidget';

const classes = {
    widgetBottomDivider: `${PREFIX}-widgetBottomDivider`,
};

const StyledSection = styled('section')(({ theme, backgroundColor }) => ({
    padding: theme.spacing(5, 0),
    backgroundColor: backgroundColor || undefined,
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 0),
    },
    [`&.${classes.widgetBottomDivider}`]: {
        borderBottom: `2px solid ${theme.palette.divider}`,
        paddingBottom: theme.spacing(8),
        marginBottom: theme.spacing(3),
    },
}));

const homeWidgets = {
    video: { component: HomePageWidgetVideo, customLocaleKeys: ['videoId'] },
    textImage: { component: HomePageWidgetTextImage, customLocaleKeys: ['title', 'description', 'label'] },
    thumbnails: { component: Thumbnails, customLocaleKeys: ['title', 'description'] },
    contactForm: { component: HomePageWidgetContactForm, customLocaleKeys: ['title', 'description', 'formTitle'] },
    contactBlock: { component: HomePageWidgetContactBlock, customLocaleKeys: ['title', 'description', 'buttonLabel'] },
    accordion: { component: HomePageWidgetAccordion, customLocaleKeys: ['title', 'description'] },
    textImageTabs: { component: TextImageTabs, customLocaleKeys: ['title', 'description'] },
    banners: { component: HomePageWidgetBanners, customLocaleKeys: ['title', 'description'] },
    title: { component: HomePageWidgetTitle, customLocaleKeys: ['title', 'description'] },
    roleCards: { component: RoleCards, customLocaleKeys: ['title'] },
    carousel: { component: WidgetCarousel, customLocaleKeys: [] },
    reviews: { component: HomePageWidgetReviews, customLocaleKeys: ['title', 'description'] },
};

const componentLibraryWidgets = {
    kpi: { component: Kpi, customLocaleKeys: ['title', 'description'] },
    circleBackgroundBanners: { component: CircleBackgroundBannersWrapper, customLocaleKeys: ['title', 'description'] },
};

const widgets = { ...homeWidgets, ...componentLibraryWidgets };

function HomePageWidget({ type, bottomDivider, backgroundColor, ...props }) {
    const { component: WidgetComponent, customLocaleKeys } = widgets[type] || {};
    const { i18n } = useTranslation();

    const unifiedProps = useMemo(() => {
        if (customLocaleKeys) {
            return flattenLanguageProps(props, i18n, customLocaleKeys);
        }
        return props;
    }, [customLocaleKeys, i18n, props]);

    return (
        <StyledSection
            className={classnames({ [classes.widgetBottomDivider]: Boolean(bottomDivider) })}
            backgroundColor={backgroundColor}
        >
            {!componentLibraryWidgets[type] && !WidgetComponent?.disableTitle && (
                <WidgetTitle title={props.title} />
            )}
            {WidgetComponent ? (
                <WidgetComponent {...unifiedProps} />
            ) : (
                <em>Widget not supported.</em>
            )}
        </StyledSection>
    );
}

HomePageWidget.propTypes = {
    type: PropTypes.string.isRequired,
    bottomDivider: PropTypes.bool,
    backgroundColor: PropTypes.string,
};

export default HomePageWidget;
