import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPagePath, getRouterPath } from '../../helpers/path';
import staticPages from '../../pages/static/staticPages';

export const LinkComponent = React.forwardRef(({ children, href, to, ...rest }, ref) => {
    const { i18n } = useTranslation();
    const pages = staticPages(i18n);

    if (href) {
        return <a href={href} {...(href.indexOf('://') === -1 ? {} : { target: '_blank', rel: 'noreferrer' })} {...rest}>{children}</a>;
    }

    let cleanTo = to;

    if (to) {
        if (to.indexOf('key:') === 0) {
            cleanTo = getPagePath(pages, to.substring(4));
        } else if (to.indexOf('route:') === 0) {
            cleanTo = getRouterPath(i18n, to.substring(6), i18n.language, true);
        }
    }

    return <Link ref={ref} to={cleanTo} {...rest}>{children}</Link>;
});

export default LinkComponent;
