import { CircleBackgroundBanners } from '@athenagroup/components';
import React from 'react';
import { LinkComponent } from '../../general/LinkComponent';

function CircleBackgroundBannersWrapper({ ...rest }) {
    return (
        <CircleBackgroundBanners LinkComponent={LinkComponent} {...rest} />
    );
}

export default CircleBackgroundBannersWrapper;
